import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Sidebar from './chat_sidebar.js';
import { Breadcrumb, Layout, Menu } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { FilePdfOutlined, FileExcelOutlined, FileImageOutlined } from '@ant-design/icons';

import { FaBookmark } from "react-icons/fa"; // Install react-icons if not already done
const TopBar = () => {
  return (
    <div className="flex justify-between items-center mb-4 ml-10 mr-10">
      {/* Breadcrumb */}
      <div>
        <h2 className="text-md font-semibold text-gray-800 whitespace-nowrap">Home / Chat</h2>
      </div>

      {/* Buttons */}
      <div className="flex items-center space-x-5">
        {/* New Chat Button */}
        <button className="flex items-center space-x-2 px-4 py-2 bg-gray-100 text-gray-700 shadow-md hover:bg-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
          <span className='hidden md:block'>New&nbsp;Chat</span>
        </button> 

        {/* Bookmark Icon */}
        <button className="p-3 bg-gray-100 text-gray-700 shadow-md hover:bg-white">
          <FaBookmark className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};


function QuestionInput() {
  const [question, setQuestion] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const popupRef = useRef(null);

  // Close popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full max-w-3xl p-4">
        {/* Popup */}
        {isPopupVisible && (
          <div ref={popupRef} className="bg-gray-100 p-2 rounded-lg ">
            <div className="grid grid-flow-col auto-cols-max gap-4">
              {/* File type buttons */}
              <button className="flex flex-col items-center px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400">
                <span className=""><FilePdfOutlined /></span>
                <span className="text-sm">PDF</span>
              </button>
              <button className="flex flex-col items-center px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400">
                <span className=""><FileExcelOutlined /></span>
                <span className="text-sm">Excel</span>
              </button>
              <button className="flex flex-col items-center px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400">
                <span className=""><FileImageOutlined /></span>
                <span className="text-sm">Image</span>
              </button>
            </div>
          </div>
        )}

        <div className="flex items-center bg-gray-200 p-5 rounded-lg max-w-3xl mx-auto mt-6">
          {/* Link Icon */}
          <button onClick={() => setIsPopupVisible(!isPopupVisible)} className="bg-white rounded-full p-2 mr-2 text-gray-500 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l3-3m0 0l3 3m-3-3v12m0 0l-3-3m3 3l-3 3" />
            </svg>
          </button>


          {/* Input Field */}
          <input
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Type here to ask question..."
            className="flex-1 bg-transparent outline-none text-gray-600 placeholder-gray-400 text-sm"
          />

          {/* Arrow Icon */}
          <button className="bg-white rounded-full p-2 ml-2 text-gray-500 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14M12 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

const ChatUI = () => {
  const messages = [
    {
      id: 1,
      text: "To add a timezone-aware time to a date variable in Python, you can use the datetime module along with the pytz library or the zoneinfo module (available in Python 3.9 and later). Here's how to do it:",
      isUser: true,
    },
    {
      id: 2,
      text: "To add a timezone-aware time to a date variable in Python, you can use the datetime module along with the pytz library or the zoneinfo module (available in Python 3.9 and later). Here's how to do it:",
      isUser: false,
    },
    {
      id: 3,
      text: "To add a timezone-aware time to a date variable in Python, you can use the datetime module along with the pytz library or the zoneinfo module (available in Python 3.9 and later). Here's how to do it:",
      isUser: true,
    },
    {
      id: 4,
      text: "To add a timezone-aware time to a date variable in Python, you can use the datetime module along with the pytz library or the zoneinfo module (available in Python 3.9 and later). Here's how to do it:",
      isUser: false,
    },
    {
      id: 1,
      text: "To add a timezone-aware time to a date variable in Python, you can use the datetime module along with the pytz library or the zoneinfo module (available in Python 3.9 and later). Here's how to do it:",
      isUser: true,
    },
    {
      id: 2,
      text: "To add a timezone-aware time to a date variable in Python, you can use the datetime module along with the pytz library or the zoneinfo module (available in Python 3.9 and later). Here's how to do it:",
      isUser: false,
    },
    {
      id: 3,
      text: "To add a timezone-aware time to a date variable in Python, you can use the datetime module along with the pytz library or the zoneinfo module (available in Python 3.9 and later). Here's how to do it:",
      isUser: true,
    },
    {
      id: 4,
      text: "To add a timezone-aware time to a date variable in Python, you can use the datetime module along with the pytz library or the zoneinfo module (available in Python 3.9 and later). Here's how to do it:",
      isUser: false,
    },
  ];

  return (
    <div className="flex flex-col h-[600px] w-2/3 mx-auto">
      <div className="flex-1 overflow-y-auto p-2 space-y-4">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`flex ${message.isUser ? "justify-end" : "justify-start"
              }`}
          >
            <div
              className={`max-w-lg p-3 rounded-lg shadow ${message.isUser
                ? "text-gray-800"
                : "text-gray-800"
                }`}
            >
              {message.text}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const DocumentsPage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState('');
  const { token } = useParams();
  const userEmail = localStorage.getItem('userEmail');
  const { Header, Content, Sider } = Layout;
  const colorBgContainer = "background-color: #f0f2f5";
  const borderRadiusLG = "20px";


  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      // You can also set userEmail if you want to store it again
      localStorage.setItem('userEmail', userEmail);
    }
  }, [token, userEmail]); // Added for notification
  const navigate = useNavigate();

  const fetchDocuments = async () => {
    try {
      // const response = await axios.post('https://m-zbr0.onrender.com/api/documents/', formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   },
      // });
      const response = await axios.get(`https://m-zbr0.onrender.com/api/documents/?userEmail=${userEmail}`);
      // const response = await axios.get(`http://localhost:8001/api/documents/?userEmail=${userEmail}`);
      setDocuments(JSON.parse(response.data));
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [userEmail]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('user_id', userEmail);
    setUploading(true);
    try {
      const response = await axios.post('https://m-zbr0.onrender.com/api/documents/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setNotification(' Large documents take time to process.');
      setTimeout(() => setNotification(''), 5000); // Hide the notification after 5 seconds
      // const response = await axios.post('http://localhost:8001/api/documents/', formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   },
      // });
      console.log(response.data);
      fetchDocuments();

      // Show pop-up notification after successful upload

    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleDocumentSelection = (docId) => {
    setSelectedDocuments((prev) =>
      prev.includes(docId) ? prev.filter((id) => id !== docId) : [...prev, docId]
    );
  };

  const handleAnswerQuestions = () => {
    if (selectedDocuments.length > 0) {
      console.log(selectedDocuments);
      navigate('/answer-questions', { state: { selectedDocuments } });
    }
  };

  const handleDeleteDocument = async (docId) => {
    try {
      await axios.delete(`http://m-zbr0.onrender.com/api/documents/${docId}/`);
      // await axios.delete(`http://localhost:8001/api/documents/${docId}/`);
      fetchDocuments(); // Refresh the document list
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  return (
    <div className="min-h-[calc(100vh-65px)] flex">
      {/* Sidebar or other content */}

      <Sidebar docs={[]} />

      {/* Main Content */}
      <div className="flex-1 p-4">

        <TopBar />
        <ChatUI />
        <QuestionInput />
      </div>
    </div>

    // <div className="flex flex-col md:flex-row h-screen bg-gray-100">
    //   {/* Sidebar */}
    //   <div className="w-full md:w-64 bg-white shadow-md">
    //     <div className="p-4">
    //       <h1 className="text-2xl font-bold">Project_M</h1>
    //     </div>
    //     <aside className="w-full md:w-64 bg-white p-6 border-r">
    //       <nav>
    //         <ul className="space-y-2">
    //           <li>
    //             <Link 
    //               to="/documentpage" 
    //               className="flex items-center space-x-2 text-gray-700 hover:bg-gray-100 rounded p-2"
    //             >
    //               Documents
    //             </Link>
    //           </li>
    //           <button
    //           onClick={handleAnswerQuestions}
    //           disabled={selectedDocuments.length === 0}
    //           className={`w-full px-4 py-2 rounded-md ${selectedDocuments.length > 0 ? 'bg-blue-500 text-white hover:bg-blue-600' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
    //         >
    //           Answer Questions
    //         </button>
    //           <li>
    //             <Link 
    //               to="/support" 
    //               className="flex items-center space-x-2 text-gray-700 hover:bg-gray-100 rounded p-2"
    //             >
    //               Help
    //             </Link>
    //           </li>
    //         </ul>
    //       </nav>
    //     </aside>
    //   </div>

    //   {/* Main content */}
    //   <div className="flex-1 overflow-auto p-4 md:p-8">
    //     <header className="bg-white shadow-sm mb-6">
    //       <div className="max-w-full mx-auto py-4 px-4 sm:px-6 lg:px-8">
    //         <h2 className="text-lg font-semibold text-gray-900">All documents</h2>
    //       </div>
    //     </header>

    //     <main className="max-w-full mx-auto">
    //       <h1 className="text-3xl font-bold mb-6">Documents</h1>

    //       {/* Upload Form */}
    //       <div className="bg-white p-6 rounded-lg shadow-md mb-6">
            // <input 
            //   type="file" 
            //   onChange={handleFileChange} 
            //   className="mb-4 w-full"
            // />
    //         <button
    //           onClick={handleFileUpload}
    //           disabled={uploading}
    //           className={`w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 ${uploading ? 'opacity-50 cursor-not-allowed' : ''}`}
    //         >
    //           {uploading ? 'Uploading...' : 'Upload Document'}
    //         </button>
    //       </div>

    //       {/* Pop-up Notification */}
    //       {notification && (
    //         <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative mb-4" role="alert">
    //           <span className="block sm:inline">{notification}</span>
    //         </div>
    //       )}

    //       {documents.length > 0 ? (
    //         <div className="bg-white rounded-lg shadow-md overflow-hidden">
    //           <table className="w-full">
    //             <thead className="bg-gray-50">
    //               <tr>
    //                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Select</th>
    //                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
    //                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
    //                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
    //                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
    //               </tr>
    //             </thead>
    //             <tbody className="bg-white divide-y divide-gray-200">
    //               {documents.map((doc) => (
    //                 <tr key={doc.id}>
    //                   <td className="px-6 py-4 whitespace-nowrap">
    //                     <input 
    //                       type="checkbox" 
    //                       checked={selectedDocuments.includes(doc.id)}
    //                       onChange={() => handleDocumentSelection(doc.id)}
    //                       className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
    //                     />
    //                   </td>
    //                   <td className="px-6 py-4 whitespace-nowrap">{doc.name}</td>
    //                   <td className="px-6 py-4 whitespace-nowrap">
    //                     <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${doc.processed ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
    //                       {doc.processed ? 'Ready to use' : 'Processing'}
    //                     </span>
    //                   </td>
    //                   <td className="px-6 py-4 whitespace-nowrap">{new Date(doc.upload_date).toLocaleString()}</td>
    //                   <td className="px-6 py-4 whitespace-nowrap">
    //                     <button
    //                       onClick={() => handleDeleteDocument(doc.id)}
    //                       className="text-red-600 hover:text-red-900"
    //                     >
    //                       Delete
    //                     </button>
    //                   </td>
    //                 </tr>
    //               ))}
    //             </tbody>
    //           </table>
    //         </div>
    //       ) : (
    //         <p className="text-gray-500">No documents found</p>
    //       )}
    //     </main>
    //   </div>
    // </div>
  );
};

export default DocumentsPage;
