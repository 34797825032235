import React, { useState, useEffect, useRef } from 'react';
import { api_url } from './values.js'
import axios from 'axios';
import { Skeleton } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from './chat_sidebar.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Popconfirm } from 'antd';



const TopBar = ({ fetchDocs }) => {
    const userEmail = localStorage.getItem('userEmail');
    const [isUploading, setUploading] = useState(false);
    const fileInputRef = useRef(null);


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // setSelectedFile(event.target.files[0]);
            setUploading(true);
            onFileUpload(file)
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const onFileUpload = async (selectedFile) => {
        if (!selectedFile) return;
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('user_id', userEmail);


        try {
            console.log('hiii')
            const response = await axios.post(`${api_url}/documents/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            // setNotification(' Large documents take time to process.');
            // setTimeout(() => setNotification(''), 5000); // Hide the notification after 5 seconds
            // const response = await axios.post('http://localhost:8001/api/documents/', formData, {
            //   headers: {
            //     'Content-Type': 'multipart/form-data',
            //   },
            // });
            console.log(response.data);
            if (response.status === 200) {
                toast.success("File Uploaded Successfully", {
                    position: "top-right",

                });
                fetchDocs();
            }

            // Show pop-up notification after successful upload

        } catch (error) {
            console.log(error.response)
            toast.error(error.response.data.error, {
                position: "top-right",
            });
        } finally {
            setUploading(false);
        }
    };


    return (
        <div className="flex justify-between items-center mb-4 ml-10 mr-10">
            {/* Breadcrumb */}
            <div>
                <h2 className="text-sm font-semibold text-gray-800 whitespace-nowrap">Home / Chat / Documents</h2>
            </div>

            {/* Buttons */}
            <div className="flex items-center space-x-5">
                {/* New Chat Button */}
                <button onClick={handleButtonClick} className="flex items-center space-x-2 px-4 py-2 bg-gray-100 text-gray-700 shadow-md hover:bg-white">
                    {isUploading ? (
                        <>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-4 h-4 animate-spin"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 2.25c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12 6.615 2.25 12 2.25z"
                                />
                            </svg>
                            <span className="hidden md:block">Uploading...</span>
                        </>
                    ) : (
                        <>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-4 h-4"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 4.5v15m7.5-7.5h-15"
                                />
                            </svg>
                            <span className='hidden md:block'>Upload New</span></>
                    )}
                </button>

                <input
                    ref={fileInputRef}
                    type="file"
                    onChange={handleFileChange}
                    className="hidden"
                    accept='application/pdf'
                />

            </div>
        </div>
    );
};

const FileList = ({ files, fetchDocs }) => {
    const [loadingId, setLoadingId] = useState(null);
    // const [selectedDocuments, setSelectedDocuments] = useState([]);
    const navigate = useNavigate();

    const deleteDocuments = async (id) => {
        try {

            const response = await axios.delete(`${api_url}/documents/${id}/`);
            // const response = await axios.get(`http://localhost:8001/api/documents/?userEmail=${userEmail}`);
            // setDocuments(JSON.parse(response.data));
            console.log(response)

            if (response.status === 200) {
                toast.success("File Deleted Successfully", {
                    position: "top-right",
                });

                fetchDocs();
            }

        } catch (error) {
            console.error('Error fetching documents:', error);
        } finally {
            setLoadingId(null);
        }
    };

    const confirm = (id) => {
        console.log(id);
        setLoadingId(parseInt(id));
        deleteDocuments(id)
    };
    const cancel = (e) => {
        console.log(e);
    };

    // const handleStartChat = () => {

    // };

    const handleDocumentSelection = (docId) => {
        const updatedDocuments = [docId];
        if (updatedDocuments.length > 0) {
            localStorage.setItem('appState', JSON.stringify(updatedDocuments));
            navigate('/chat', { state: { selectedDocuments: updatedDocuments } });
        }
        
    };

    return (
        <div className="space-y-2">
            {files.length <= 0 ? (
                <Skeleton active />
            ) : (
                files.map((file) => (
                    <div key={file.id} className="flex items-center justify-between bg-gray-300 rounded-md p-2 px-5">
                        <span>{file.name}</span>
                        <div className="flex space-x-2">
                            <Button type="primary" onClick={() => handleDocumentSelection(file.id)} >
                                Start Chat
                            </Button>
                            <Popconfirm
                                title="Delete file"
                                description="Are you sure to delete this file?"
                                onConfirm={() => confirm(file.id)}
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                                placement="left"
                            >
                                {loadingId === file.id ? (
                                    <Button type="dashed" danger loading iconPosition="end">
                                        Loading
                                    </Button>
                                ) : (
                                    <Button type="dashed" danger>Delete</Button>
                                )}


                            </Popconfirm>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};


const Documents = () => {
    const [documents, setDocuments] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const { token } = useParams();
    const userEmail = localStorage.getItem('userEmail');

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
            // You can also set userEmail if you want to store it again
            localStorage.setItem('userEmail', userEmail);
        }
    }, [token, userEmail]); // Added for notification
    const navigate = useNavigate();

    const fetchDocuments = async () => {
        try {
            //   headers: {
            //     'Content-Type': 'multipart/form-data',
            //   },
            // });
            const response = await axios.get(`${api_url}/documents/?userEmail=${userEmail}`);
            // const response = await axios.get(`http://localhost:8001/api/documents/?userEmail=${userEmail}`);
            setDocuments(JSON.parse(response.data));
            console.log(JSON.parse(response.data))
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };

    useEffect(() => {
        fetchDocuments();
    }, [userEmail]);

    return (
        <div className="min-h-[calc(100vh-65px)] flex">
            {/* Sidebar or other content */}

            <Sidebar docs={documents} />

            {/* Main Content */}
            <div className="flex-1 p-4">

                <TopBar fetchDocs={fetchDocuments} />
                <FileList files={documents} fetchDocs={fetchDocuments} />

            </div>
            <ToastContainer />
        </div>

    );
};

export default Documents;
