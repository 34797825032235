import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Sidebar from './chat_sidebar.js';
import { Skeleton, Button, Tooltip } from 'antd';
import { json, useLocation } from 'react-router-dom';
import { LinkOutlined, FileExcelOutlined, FileImageOutlined, SendOutlined } from '@ant-design/icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaBookmark } from "react-icons/fa"; // Install react-icons if not already done
import { api_url } from './values.js';
import ReactMarkdown from 'react-markdown';
import * as XLSX from 'xlsx';

const TopBar = ({ docId }) => {

  const onStartChat = () => {

    localStorage.removeItem('appState');
    window.location.reload();
  };

  const onBookmarkClick = () => {
    toast.warning("Coming soon!", {
      position: "top-right",
    })

  };
  return (
    <div className="flex justify-between items-center mb-4 ml-10 mr-10">
      {/* Breadcrumb */}
      <div>
        <h2 className="text-md font-semibold text-gray-800 whitespace-nowrap">Home / Chat</h2>
      </div>

      {/* Buttons */}
      <div className="flex items-center space-x-5">
        {/* New Chat Button */}
        <button onClick={onStartChat} className="flex items-center space-x-2 px-4 py-2 bg-gray-100 text-gray-700 shadow-md hover:bg-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
          <span className='hidden md:block'>New&nbsp;Chat</span>
        </button>

        {/* Bookmark Icon */}
        <button onClick={onBookmarkClick} className="p-3 bg-gray-100 text-gray-700 shadow-md hover:bg-white">
          <FaBookmark className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

function QuestionInput({ docId, onChatUpdate, preQuestion = "" }) {
  const [question, setQuestion] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const buttonRef = useRef();
  const fileInputRef = useRef(null);
  const [questions, setQuestions] = useState([]);

  var lstQuestion = ""

  const handleExcelFileChange = (e) => {
    try {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        try {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });

          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          const headerRow = jsonData[0];
          console.log(headerRow)
          const questionColumnIndex = headerRow.findIndex(
            (header) => header.toLowerCase() === 'question'
          );

          if (questionColumnIndex === -1) {
            toast.error(`No "Question" column found in the Excel file`, {
              position: "top-right",

            });
            return;
          }

          const extractedQuestions = jsonData
            .slice(1)
            .map((row) => row[questionColumnIndex])
            .filter((question) => question);

          console.log('Extracted Questions:', extractedQuestions);
          setQuestions(extractedQuestions);
        } catch (error) {
          console.log(error.response)
          toast.error(error.response.data.error, {
            position: "top-right",
          });
        } finally {
          setIsLoading(false);
        }
      };

      reader.readAsArrayBuffer(file);

    } catch (error) {
      console.log(error.response)
      toast.error(error.response.data.error, {
        position: "top-right",
      });
    } finally {
      setIsLoading(false);
    }
  };


  const popupRef = useRef(null);

  // Close popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, []);

  useEffect(() => {
    if (preQuestion.length > 0) {
      if (lstQuestion !== preQuestion) {
        setQuestion(preQuestion)
        // buttonRef.current.click(); 
      }
    }
  }, [preQuestion]);


  const onSendQuestion = async () => {
    try {

      if (question.length > 0) {
        let userEmail = localStorage.getItem('userEmail');
        setIsLoading(true);

        const response = await axios.post(`${api_url}/answer-question/`, {
          question,
          documentIds: [docId],
          userEmail: userEmail,
        });

        setQuestion('');
        setIsLoading(false);
        onChatUpdate();
      } else {
        toast.error('Question cannot be empty!');
      }
    }
    catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error('Error sending question');
    }
  }



  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleExcelFileChange(event);
      setIsLoading(true);
      // onFileUpload(file)

    }
  };


  const handleExcelQuestionsSubmit = async () => {
    if (questions.length === 0) return;
    setIsLoading(true);
    let userEmail = localStorage.getItem('userEmail');

    for (const question of questions) {
      try {

        await axios.post(`${api_url}/answer-question/`, {
          question,
          documentIds: [docId],
          userEmail: userEmail,
        });

      } catch (error) {
        console.error('Error getting answer:', error);
        toast.error('Error getting answer');
      }
    }
    setQuestions([]);
    setIsLoading(false);
    onChatUpdate();

  };


  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full max-w-3xl p-4"
      style={{ left: `calc(50% + var(--sidebar-offset))` }}>
      {/* Popup */}
      {isPopupVisible && (
        <div ref={popupRef} className="bg-gray-100 p-2 rounded-lg ">
          <div className="grid grid-flow-col auto-cols-max gap-4">
            {/* File type buttons */}

            <button className="flex flex-col items-center px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400">
              <span className=""><FileExcelOutlined /></span>
              <span className="text-sm">Excel</span>
            </button>

          </div>
        </div>
      )}



      <div className="flex items-center bg-gray-200 p-5 rounded-lg max-w-3xl mx-auto mt-6">
        {/* Link Icon */}
        <Tooltip title="Upload Excel sheet to ask bulk question.">

          <Button type="dashed" onClick={handleButtonClick} loading={isLoading} icon={<FileExcelOutlined />} iconPosition="start" />
        </Tooltip>

        <input
          ref={fileInputRef}
          type="file"
          onChange={handleFileChange}
          className="hidden"
        // accept='application/pdf'
        />


        {/* Input Field */}
        <textarea rows="1" value={question}
          onInput={(e) => {
            const textarea = e.target;
            textarea.style.height = "auto"; // Reset height to auto to calculate scrollHeight
            textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on scrollHeight
            setQuestion(textarea.value);
          }}

          placeholder="Type here to ask question..."
          style={{ lineHeight: "1.5" }}
          className="flex-1 pl-3 pr-3 bg-transparent outline-none text-gray-600 placeholder-gray-400 text-sm overflow-hidden resize-none"
        ></textarea>
        {questions.length > 0 ? (
          <Button type="dashed" ref={buttonRef} onClick={handleExcelQuestionsSubmit} loading={isLoading} icon={<SendOutlined />} iconPosition="end" >Ask Multiple Questions</Button>
        ) : (
          <Button type="dashed" ref={buttonRef} onClick={onSendQuestion} loading={isLoading} icon={<SendOutlined />} iconPosition="end" />
        )}
      </div>
    </div>
  );
}

const ChatUI = ({ docId, userChat }) => {
  return (
    <div className="flex flex-col h-[700px] w-2/3 mx-auto">
      <div className="flex-1 overflow-y-auto p-2 space-y-4">
        {userChat.length > 0 ? (
          <div className="p-4 md:p-6 rounded-md ">
            {/* border border-[#565869] */}
            {/* <h2 className="text-xl font-bold mb-4">Answers from Excel:</h2> */}
            {userChat.map((item, index) => (
              <div key={index} className="mb-6 last:mb-0 p-4 rounded-md">
                <p className="text-[#10A37F] font-semibold mb-2">
                  <span className="text-[#4A4B59]">Question:</span> {item.question}
                </p>
                <div className="text-[#4A4B59] pl-4 border-l-2 border-[#10A37F]">
                  <ReactMarkdown>{item.answer}</ReactMarkdown>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Skeleton active />
        )}
      </div>
    </div>
  );
};

const DocumentSelection = ({ onSelectDocument }) => {
  return (
    <div className="flex justify-center items-center h-3/6">
      <div className="text-center p-6 border border-gray-300 rounded-lg shadow-lg bg-white max-w-md w-full">
        <div className="text-blue-500 text-4xl mb-4">
          <i className="fas fa-file-alt"></i> {/* Replace with an appropriate icon */}
        </div>
        <h2 className="text-xl font-semibold mb-2">Select a document to start chat</h2>
        <p className="text-gray-600 mb-4">Choose a document below to begin your conversation.</p>
        <a href='/documents'
          className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
        >
          Select Document
        </a>
        <div className="mt-4 text-sm text-gray-500">
          <p>Once a document is selected, you can start the chat.</p>
        </div>
      </div>
    </div>
  );
};

const ChatPage = () => {
  var is_loaded = false;
  const location = useLocation();
  const [selectedDocID, setSelectedID] = useState(0);;
  const stateFromStorage = localStorage.getItem('appState');
  const questionFromStorage = localStorage.getItem('questionState');
  const [userChat, setUserChat] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [preQuestion, setPreQuestion] = useState("");
  const [predefinedQuestions, setPredefinedQuestions] = useState([]);
  const userEmail = localStorage.getItem('userEmail');

  const fetchDocuments = async () => {
    try {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   },
      // });
      const response = await axios.get(`${api_url}/documents/?userEmail=${userEmail}`);
      setDocuments(JSON.parse(response.data));
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  const onSetPreQuestion = (question) => {
    setPreQuestion(question);
  }

  const getUserChat = async () => {
    try {

      let userEmail = localStorage.getItem('userEmail');
      const response = await axios.get(`${api_url}/user-questions/?userEmail=${userEmail}`);
      // console.log(response.data);
      setUserChat(response.data);
    } catch (error) {
      toast.error(error.response.data.error, {
        position: "top-right",

      });
    }
  };


  const getPreDefinedQuestion = async () => {
    try {
      const response = await axios.get(`${api_url}/predefinedQuestion/`);
      setPredefinedQuestions(response.data);
    } catch (error) {
      toast.error(error.response.data.error, {
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    if (!is_loaded) {
      getUserChat();
      fetchDocuments();
      getPreDefinedQuestion();
      is_loaded = true;
    }

    if (stateFromStorage) {
      const appState = JSON.parse(stateFromStorage);
      console.log(appState, 'appState');
      setSelectedID(appState[0]);
    } else {
      setSelectedID(0);
    }

    if (questionFromStorage) {
      let question = JSON.parse(questionFromStorage)
      setPreQuestion(question);
      localStorage.removeItem('questionState');
    }
  }, []);

  return (
    <div className="min-h-[calc(100vh-65px)] relative flex">
      {/* Sidebar or other content */}

      <Sidebar docs={documents} questions={predefinedQuestions} userChat={userChat} onPreHistory={onSetPreQuestion} />

      {/* Main Content */}
      <div className="flex-1 p-4">

        <TopBar docId={selectedDocID} />
        {selectedDocID > 0 ? (
          <>
            <ChatUI docId={selectedDocID} userChat={userChat} />
            <QuestionInput docId={selectedDocID} onChatUpdate={getUserChat} preQuestion={preQuestion} />
          </>
        ) : (
          <DocumentSelection />
        )}
      </div>
      <ToastContainer />
    </div>


  );
};

export default ChatPage;
