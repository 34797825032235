import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Skeleton } from 'antd';
import { api_url } from './values.js';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Sidebar from './chat_sidebar.js';

const TopBar = () => {
    return (
        <div className="flex justify-between items-center mb-4 ml-10 mr-10">
            {/* Breadcrumb */}
            <div>
                <h2 className="text-sm font-semibold text-gray-800 whitespace-nowrap">Home / Chat / History</h2>
            </div>

            {/* Buttons */}
            <div className="flex items-center space-x-5 hidden">
                {/* New Chat Button */}
                <button className="flex items-center space-x-2 px-4 py-2 bg-gray-100 text-gray-700 shadow-md hover:bg-white">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                        />
                    </svg>
                    <span className='hidden md:block'>Upload New</span>
                </button>

            </div>
        </div>
    );
};


const FileList = ({ files, fetchDocs }) => {
    const [loadingId, setLoadingId] = useState(null);
    // const [selectedDocuments, setSelectedDocuments] = useState([]);
    const navigate = useNavigate();

    return (
        <div className="space-y-2">
            {files.length <= 0 ? (
                <Skeleton active />
            ) : (
                files.map((file) => (
                    <div key={file.id} className="flex items-center justify-between bg-gray-300 rounded-md p-2 px-5">
                        <span>{file.question}</span>
                        <div className="flex items-end justify-end space-x-2">
                            <small className='block'>{new Date(file.created_at).toLocaleDateString()}</small>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};


const History = () => {
    const [userChat, setUserChat] = useState([]);
    const { token } = useParams();
    const userEmail = localStorage.getItem('userEmail');

    const getUserChat = async () => {
        try {
            const response = await axios.get(`${api_url}/user-questions/?userEmail=${userEmail}`);
            console.log(response.data);
            setUserChat(response.data);
        } catch (error) {
            toast.error(error.response.data.error, {
                position: "top-right",

            });
        }
    };

    useEffect(() => {
        getUserChat();
    }, [userEmail]);


    return (
        <div className="min-h-[calc(100vh-65px)] flex">
            {/* Sidebar or other content */}

            <Sidebar userChat={userChat} />

            {/* Main Content */}
            <div className="flex-1 p-4">

                <TopBar />
                <FileList files={userChat} fetchDocs={getUserChat} />

            </div>
            <ToastContainer />
        </div>

    );
};

export default History;
