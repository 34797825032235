import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div className="bg-blue-600 min-h-screen font-sans">
      <header className="text-white text-center py-16 px-4">
        <h1 className="text-4xl md:text-5xl font-bold mb-4">Privacy Policy for Maxiumsys</h1>
        <p className="text-xl md:text-2xl mb-8">
          We are committed to protecting your privacy and ensuring the security of your personal information.
        </p>
      </header>

      <main className="bg-white py-16 px-4">
        <div className="max-w-4xl mx-auto">
          <section className="mb-12">
            <h2 className="text-3xl font-bold text-blue-600 mb-4">1. Introduction</h2>
            <p className="mb-4">
              Welcome to Maxiumsys! We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website, maxiumsys.com (the "Site"). Please take a moment to read through this policy to understand our practices regarding your personal data. By accessing or using our Site, you agree to the terms of this Privacy Policy.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-blue-600 mb-4">2. Information We Collect</h2>
            <h3 className="text-2xl font-semibold mb-2">Personal Data:</h3>
            <p className="mb-4">
              We collect personal information that you voluntarily provide to us when you interact with our Site. This may include your name, email address, phone number, postal address, and any other information you choose to provide when:
            </p>
            <ul className="list-disc pl-8 mb-4">
              <li>Registering an account on our Site.</li>
              <li>Subscribing to our newsletter or marketing communications.</li>
              <li>Contacting us through our customer support channels.</li>
            </ul>
            <h3 className="text-2xl font-semibold mb-2">Automatically Collected Information:</h3>
            <p className="mb-4">
              When you visit our Site, we automatically collect certain information about your device and usage patterns. This may include your IP address, browser type, referring/exit pages, and operating system. We use cookies and similar tracking technologies to enhance your user experience and analyze trends.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-blue-600 mb-4">3. How We Use Your Information</h2>
            <p className="mb-4">We may use the information we collect for the following purposes:</p>
            <ul className="list-disc pl-8 mb-4">
              <li><strong>Provide, Operate, and Maintain our Site:</strong> To deliver the products and services you request, operate our Site effectively, and ensure a consistent user experience.</li>
              <li><strong>Improve and Personalize User Experience:</strong> To understand how users interact with our Site, develop new features, and enhance the functionality and performance of our Site.</li>
              <li><strong>Communicate with You:</strong> To respond to your inquiries, provide customer support, send administrative information, and communicate with you about products, services, promotions, and events.</li>
              <li><strong>Marketing and Promotions:</strong> To send you marketing communications and promotional offers that may be of interest to you, where permitted by law and in accordance with your preferences.</li>
              <li><strong>Legal Compliance:</strong> To comply with legal obligations, such as responding to legal requests, preventing fraud, enforcing our terms and policies, and protecting our rights and interests.</li>
            </ul>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-blue-600 mb-4">4. Sharing Your Information</h2>
            <p className="mb-4">
              We do not sell your personal information to third parties. However, we may share your information in the following circumstances:
            </p>
            <ul className="list-disc pl-8 mb-4">
              <li><strong>Service Providers:</strong> We may engage trusted third-party service providers to assist us in operating our Site or conducting our business. These service providers have access to your information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</li>
              <li><strong>Legal Compliance:</strong> We may disclose your information if required to do so by law or legal process, or if we believe that such action is necessary to comply with applicable laws, regulations, or governmental requests.</li>
              <li><strong>Business Transfers:</strong> If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, or sale of our assets, your information may be transferred as part of that transaction. In such cases, we will notify you via email and/or a prominent notice on our Site of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.</li>
            </ul>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-blue-600 mb-4">5. Data Security</h2>
            <p className="mb-4">
              We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, use, disclosure, alteration, and destruction. However, please be aware that no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-blue-600 mb-4">6. Your Rights and Choices</h2>
            <p className="mb-4">Depending on your jurisdiction, you may have certain rights regarding your personal information, including:</p>
            <ul className="list-disc pl-8 mb-4">
              <li><strong>Access and Correction:</strong> You have the right to access the personal information we hold about you and request corrections or updates to ensure its accuracy.</li>
              <li><strong>Deletion:</strong> You may request the deletion of your personal information under certain circumstances, such as when it is no longer necessary for the purposes for which it was collected.</li>
              <li><strong>Object to Processing:</strong> You have the right to object to the processing of your personal information where we are relying on legitimate interests (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground.</li>
              <li><strong>Restriction of Processing:</strong> You have the right to request the restriction of processing of your personal information, such as when you contest the accuracy of your personal information or when the processing is unlawful.</li>
              <li><strong>Data Portability:</strong> You have the right to receive the personal information concerning you in a structured, commonly used, and machine-readable format and to transmit those data to another controller.</li>
              <li><strong>Withdraw Consent:</strong> If we have collected and processed your personal information with your consent, you have the right to withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent.</li>
            </ul>
            <p className="mb-4">To exercise any of these rights, please contact us using the contact information provided below.</p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-blue-600 mb-4">7. Children's Privacy</h2>
            <p className="mb-4">
              Our Site is not directed to children under the age of 13, and we do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13 without parental consent, we will take steps to delete that information promptly.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-blue-600 mb-4">8. Changes to This Privacy Policy</h2>
            <p className="mb-4">
              We may update this Privacy Policy periodically to reflect changes in our practices and legal requirements. We will notify you of any changes by posting the updated Privacy Policy on our Site with a new effective date. We encourage you to review this Privacy Policy periodically for the latest information about our privacy practices.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-bold text-blue-600 mb-4">9. Contact Us</h2>
            <p className="mb-4">
              If you have any questions, concerns, or comments about this Privacy Policy or our privacy practices, please contact us:
            </p>
            <ul className="list-disc pl-8">
              <li>By email: contact@maxiumsys.com</li>
              <li>By visiting this page on our website: maxiumsys.com/contact</li>
            </ul>
          </section>
        </div>
      </main>

      <footer className="bg-gray-800 text-white text-center py-8">
        <p>&copy; 2024 Maxiumsys. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default PrivacyPolicyPage;