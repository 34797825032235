import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { api_url } from './values.js'
import { Skeleton, Button, Popconfirm } from 'antd';
import { useNavigate } from 'react-router-dom';
import Sidebar from './chat_sidebar.js';

const TopBar = () => {
    return (
        <div className="flex justify-between items-center mb-4 ml-10 mr-10">
            {/* Breadcrumb */}
            <div>
                <h2 className="text-sm font-semibold text-gray-800 whitespace-nowrap">Home / Chat / Questionnaires</h2>
            </div>

            {/* Buttons */}
            <div className="flex items-center space-x-5 hidden">
                {/* New Chat Button */}
                <button className="flex items-center space-x-2 px-4 py-2 bg-gray-100 text-gray-700 shadow-md hover:bg-white">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                        />
                    </svg>
                    <span className='hidden md:block'>Upload New</span>
                </button>

            </div>
        </div>
    );
};


const FileList = ({ questions }) => {
    console.log(questions);
    const [loadingId, setLoadingId] = useState(null);
    const navigate = useNavigate();


    const handleDocumentSelection = (question) => {
        if (question.length > 0) {
            localStorage.setItem('questionState', JSON.stringify(question));
            navigate('/chat', { state: { selectedQuestion: question } });
        }

    };

    return (
        <div className="space-y-2">
            {questions.length <= 0 ? (
                <Skeleton active />
            ) : (
                questions.map((file) => (
                    <button key={file.id}  onClick={() => handleDocumentSelection(`${file.question}`)} className="flex items-center justify-between bg-gray-300 rounded-md p-2 px-5 w-full">
                        <span>{file.question}</span>
                        
                    </button>
                ))
            )}
        </div>
    );
};


const Questionnaires = () => {
    const [questions, setQuestions] = useState([]);
    const userEmail = localStorage.getItem('userEmail');

    const fetchQuestions = async () => {
        try {
            const response = await axios.get(`${api_url}/predefinedQuestion/`);
            console.log(response)
            if (response.data.length > 0) {
                setQuestions(response.data);
            }
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };

    useEffect(() => {
        fetchQuestions();
    }, [userEmail]);


    return (
        <div className="min-h-[calc(100vh-65px)] flex">
            {/* Sidebar or other content */}

            <Sidebar questions={questions} />

            {/* Main Content */}
            <div className="flex-1 p-4">

                <TopBar />
                <FileList questions={questions} />

            </div>
        </div>

    );
};

export default Questionnaires;
