import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons';

const Sidebar = ({ docs = [], userChat = [], questions = [], onPreHistory }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    const userEmail = localStorage.getItem('userEmail');

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };


    const handleDocumentSelection = (docId) => {
        setSelectedDocuments((prev) => {
            // const updatedDocuments = prev.includes(docId) ? prev.filter((id) => id !== docId) : [...prev, docId];
            const updatedDocuments = [docId];
            if (updatedDocuments.length > 0) {
                localStorage.setItem('appState', JSON.stringify(updatedDocuments));
                if (location.pathname === '/chat') {
                    window.location.reload();
                } else {
                    navigate('/chat', { state: { selectedDocuments: updatedDocuments } }); // Navigate otherwise
                }
            }
            return updatedDocuments;
        });
    };

    const handleQuestionSelection = (question) => {
        console.log(question)
        if (typeof onPreHistory !== 'undefined') {
            onPreHistory(question);
        }
    }

    const onStartChat = () => {
        setSelectedDocuments([]);
        localStorage.removeItem('appState');
        if (location.pathname === '/chat') {
            window.location.reload();
        } else {
            navigate('/chat'); // Navigate otherwise
        }
    };

    return (
        <>
            {/* Toggle Button (on mobile) */}
            <button
                onClick={toggleSidebar}
                className="lg:hidden absolute top-70 left-0 text-gray-800 p-2 bg-white"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                    />
                </svg>
            </button>
            <div className={`${isOpen ? 'block' : 'hidden'} lg:block w-80 bg-gray-200 p-0 min-h-[calc(100vh-65px)]`}>
                <div className='h-full bg-gray-50 shadow-md p-4 flex flex-col min-h-[calc(100vh-65px)]'>
                    {/* Section: Start Chat */}

                    <div className="mb-5 mt-4">
                        <a href='#' onClick={onStartChat} className="flex justify-between items-center">
                            <h2 className="font-semibold text-lg text-gray-800">Start Chat</h2>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                                />
                            </svg>
                        </a>
                    </div>
                    <hr />

                    {/* Section: Questionnaires */}
                    {questions.length > 0 && (
                        <div className="mb-4 mt-4">
                            <div className="flex justify-between items-center">
                                <h2 className="font-semibold text-lg text-gray-800">Questionnaires</h2>
                                <a href='/questionnaires' className="text-sm text-blue-600 hover:underline">View All</a>
                            </div>
                            <div className="space-y-2 mt-2">
                                {questions.slice(0, 5).map((dt, idx) => (
                                    <button onClick={() => handleQuestionSelection(`${dt.question}`)} key={dt.id} className="bg-gray-300 text-left w-full rounded-md p-2  overflow-hidden text-ellipsis whitespace-nowrap">{dt.question}</button>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Section: Documents */}
                    {docs.length > 0 && (
                        <div className="mb-4">
                            <div className="flex justify-between items-center">
                                <h2 className="font-semibold text-lg text-gray-800">Documents</h2>
                                <a href='/documents' className="text-sm text-blue-600 hover:underline">View All</a>
                            </div>
                            <div className="space-y-2 mt-2">
                                {docs.slice(0, 5).map((dt, idx) => (
                                    <button onClick={() => handleDocumentSelection(dt.id)} key={dt.id} className="bg-gray-300 text-left w-full rounded-md p-2  overflow-hidden text-ellipsis whitespace-nowrap">{dt.name}</button>
                                ))}
                            </div>
                        </div>)}

                    {/* Section: History */}
                    {userChat.length > 0 && (
                        <div className="mb-4">
                            <div className="flex justify-between items-center">
                                <h2 className="font-semibold text-lg text-gray-800">History</h2>
                                <a href='/history' className="text-sm text-blue-600 hover:underline">View All</a>
                            </div>
                            <div className="space-y-2 mt-2">
                                {userChat.slice(0, 5).map((dt, idx) => (
                                    <button onClick={() => handleQuestionSelection(`${dt.question}`)} key={dt.id} className="bg-gray-300 text-left w-full rounded-md p-2  overflow-hidden text-ellipsis whitespace-nowrap">{dt.question}</button>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Profile Section */}
                    <div className="mt-auto pt-4 bg-gray-200 p-2 rounded-md flex items-center cursor-pointer hover:bg-gray-300">
                        <div className="flex-1">
                            <span className="font-semibold text-black">{userEmail}</span>
                            <p className="text-sm text-gray-600">profile & settings</p>
                        </div>
                        <div className="text-gray-500 hover:text-gray-700">
                            <SettingOutlined />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default Sidebar;