import AnswerQuestionsPage from "../components/answerquestion";
import Widget from "../components/navbar.js";
export default function Answer()
{
    return(
        <div>
            <Widget/>
            <AnswerQuestionsPage/>
        </div>
    )
}